/** PAGE PALMEIRAS **/
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Book.eot");
  src: url("../fonts/Gotham-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype"), url("../fonts/Gotham-Book.svg#Gotham") format("svg");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Light.eot");
  src: url("../fonts/Gotham-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham-Light.woff") format("woff"), url("../fonts/Gotham-Light.ttf") format("truetype"), url("../fonts/Gotham-Light.svg#Gotham") format("svg");
  font-style: normal;
  font-weight: 350;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Bold.eot");
  src: url("../fonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham-Bold.woff") format("woff"), url("../fonts/Gotham-Bold.ttf") format("truetype"), url("../fonts/Gotham-Bold.svg#Gotham") format("svg");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Medium.eot");
  src: url("../fonts/Gotham-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham-Medium.woff") format("woff"), url("../fonts/Gotham-Medium.ttf") format("truetype"), url("../fonts/Gotham-Medium.svg#Gotham") format("svg");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Black.eot");
  src: url("../fonts/Gotham-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham-Black.woff") format("woff"), url("../fonts/Gotham-Black.ttf") format("truetype"), url("../fonts/Gotham-Black.svg#Gotham") format("svg");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
:root{
  --color_green: #00df4b;
  --color_blue: #00baff;
  --color_black: #0f0f0f;
}
* {
  text-decoration: none;
  font-family: "Gotham";
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

#root {
  background-color: var(--color_black);
}

.App {
  text-align: center;
}

.App-logo {
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.c-banners .box-banner {
  margin-top: 16px;
}
@media (min-width: 1200px) {
  .c-banners .box-banner {
    margin-top: 0px;
  }
}

.c-banner {
  top: -50px;
}
.c-banner .box-image__banner {
  overflow: hidden;
  border-radius: 10px;
}
.c-banner .box-image__banner .banner-center {
  transition: ease-in-out 0.8s;
}
.c-banner .box-image__banner .banner-center:hover {
  transform: scale(1.05);
}

.c-home {
  min-height: 55vh;
  background: url("../../img/home/banner-home-mob-black-2024.jpeg");
  background-position: center;
  background-size: cover;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .c-home {
    background: url("../../img/home/banner-home-black-2024.jpeg");
    min-height: 50vh;
    background-size: cover;
    background-position: center;
  }
}
.c-home.cyber {
  background: url("../../img/home/banner-2-home-mob-cyber-2024.jpg");
  background-position: center;
  margin-bottom: 10px;
  background-size: cover;
}
@media (min-width: 1200px) {
  .c-home.cyber {
    min-height: 70vh;
    background: url("../../img/home/banner-2-home-cyber-2024.jpg");
    background-position: center;
    background-size: cover;
  }
}
.c-home.prorrogado {
  min-height: 55vh;
  background: url("../../img/home/banner-home-mob-prorrogamos-2024.jpg");
  background-position: center;
  background-size: cover;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .c-home.prorrogado {
    background: url("../../img/home/banner-home-prorrogamos-2024.jpg");
    min-height: 50vh;
    background-size: cover;
    background-position: center;
  }
}

.c-category {
  padding-bottom: 32px;
}
.c-category .box-category .title-category {
  color: #8E96A7;
  font-size: 16px;
  font-weight: 500;
}
.c-category .box-category a {
  max-height: 160px;
  position: relative;
}
@media (min-width: 1200px) {
  .c-category .box-category a {
    max-height: 140px;
  }
}
.c-category .box-category a .img-category {
  width: 100%;
  padding: 5px;
  object-fit: contain;
}
.c-category .box-category a .img-category:hover {
  transform: scale(1.05);
}

@media (min-width: 1200px) {
  .c-descontos .col-left {
    padding-right: 0px;
  }
}

@media (max-width: 767px){
  .c-descontos .col-right {
    margin-top: 16px;
  }
}

@media (min-width: 1200px) {
  .c-descontos .col-right {
    padding-left: 0px;
    margin-top: 0px;
  }
}

.c-banners_shirt {
  padding-bottom: 32px;
}
.c-banners_shirt .thumbnail {
  overflow: hidden;
  border-radius: 10px;
}
.c-banners_shirt .banner {
  max-height: 420px;
  transition: ease-in-out 0.6s;
}
.c-banners_shirt .banner:hover {
  transform: scale(1.05);
}
.c-banners_shirt .box-banner {
  border-radius: 10px;
  overflow: hidden;
  padding: 6px;
}
.c-banners_shirt .box-banner img {
  border-radius: 10px;
}

.c-carousel-times {
  margin-bottom: 48px;
}
.c-carousel-times .time {
  min-height: 100px;
  display: flex;
  align-items: center;
}
.c-carousel-times .title-times {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #8E96A7;
  position: relative;
  margin-right: 16px;
  margin-bottom: 36px;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .c-carousel-times .title-times {
    font-size: 18px;
  }
}
.c-carousel-times .title-times.active {
  border-bottom: 2px solid #1E6200;
  padding-bottom: 8px;
}
.c-carousel-times .times-nacionais .img-times,
.c-carousel-times .times-internacionais .img-times {
  height: 70px;
  object-fit: contain;
  transition: ease-in-out 0.8s;
  padding-right: 16px;
}
@media (min-width: 1200px) {
  .c-carousel-times .times-nacionais .img-times,
  .c-carousel-times .times-internacionais .img-times {
    padding-right: 0;
    padding: 5px;
    height: 80px;
  }
}
.c-carousel-times .times-nacionais .img-times-juventus,
.c-carousel-times .times-internacionais .img-times-juventus {
  padding: 10px;
}
.c-carousel-times .times-nacionais .img-times:hover,
.c-carousel-times .times-internacionais .img-times:hover {
  transform: scale(1.05);
}

.c-carousel-marcas {
  padding-top: 32px;
  padding-bottom: 32px;
}
.c-carousel-marcas .box-title .title-marcas {
  font-size: 16px;
  font-weight: 700;
  color: #8E96A7;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 36px;
}
@media (min-width: 1200px) {
  .c-carousel-marcas .box-title .title-marcas {
    font-size: 18px;
  }
}
.c-carousel-marcas .box-title .title-marcas::before {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: #1E6200;
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
}
.c-carousel-marcas .img-marca {
  height: 80px;
  object-fit: contain;
  transition: ease-in-out 0.6s;
}
@media (min-width: 1200px) {
  .c-carousel-marcas .img-marca:hover {
    transform: scale(1.1);
  }
}

.c-destaque {
  overflow: hidden;
}
.c-destaque .slick-destaque {
  width: 100%;
}
/* @media (min-width: 1200px) {
  .c-destaque .slick-destaque {
    width: 100%;
  }
} */
/* .c-destaque .slick-destaque .slick-track{
  display: flex;
  justify-content: center;
} */
.c-destaque .slick-destaque .item {
  padding: 12px;
  overflow: hidden;
}

.c-banners-bottom {
  overflow: hidden;
}
.c-banners-bottom .box-banner-full {
  margin-bottom: 2.5rem;
}
/* @media (min-width: 1200px) {
  .c-banners-bottom .box-banner-full {
    min-height: 50vh;
  }
} */
.c-banners-bottom .box-banner-full .banner-full {
  width: 100%;
  object-fit: cover;
}
.c-banners-bottom .slick-banners_bottom {
  width: 100%;
}
@media (min-width: 1200px) {
  .c-banners-bottom .slick-banners_bottom {
    width: 100%;
  }
}
.c-banners-bottom .slick-banners_bottom .item {
  padding: 0 15px;
}
.c-banners-bottom .slick-banners_bottom .item a {
  outline: none;
}
.c-banners-bottom .slick-banners_bottom .bannersBottom {
  width: 100%;
  border-radius: 8px;
}

@media (min-width: 1200px) {
  .c-Install {
    margin: 2rem auto;
  }
  .slick-dots{
    display: none!important;
  }
}
.c-Install .container-app {
  min-height: 400px;
}
.c-Install .container-app p{
  color: #fff;
}
/* .c-Install .container-app .app-install {
  height: 420px;
}
@media (min-width: 1200px) {
  .c-Install .container-app .app-install {
    bottom: -40%;
    left: -40%;
    position: absolute;
    height: 480px;
  }
} */
.c-Install .container-install {
  position: relative;
  justify-content: center;
  margin-top: 3.5rem;

}

.c-destaque {
  padding-top: 32px;
  padding-bottom: 32px;
}
.c-destaque .title-destaque {
  font-size: 18px;
  color: #8E96A7;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
@media (min-width: 1200px) {
  .c-destaque .title-destaque {
    font-size: 20px;
  }
}
.c-destaque .container-card {
  padding: 8px;
}
.c-destaque .link-destaque {
  text-decoration: none;
}
.c-destaque .link-destaque .card-destaque {
  overflow: hidden;
}
.c-destaque .link-destaque .card-destaque .img-card {
  width: 100%;
  object-fit: contain;
  position: relative;
  transition: ease-in-out 0.6s;
  border-radius: 10px;
}
@media (min-width: 1200px) {
  .c-destaque .link-destaque .card-destaque .img-card {
    border-radius: 0px;
  }
}
.c-destaque .link-destaque .card-destaque .img-card:hover {
  transform: scale(1.05);
}
.c-destaque .link-destaque .card-destaque .legend-card {
  width: 100%;
  z-index: 9;
  height: 40px;
  background-color: var(--color_black);
  border: 1px solid #262626;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: relative;
  color: #8E96A7;
  font-weight: 400;
  text-transform: uppercase;
}
.c-destaque .link-destaque:hover {
  text-decoration: none;
}

.c-footer {
  padding-top: 32px;
  padding-bottom: 30px;
}
.c-footer .container {
  border-top: 1px solid #424242;
}
.c-footer .title-footer {
  margin-top: 32px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}
.c-footer .title-footer-mob {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.c-footer .title-footer-mob .icon-down {
  height: 10px;
}
.c-footer .links-footer {
  color: #8E96A7;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 400;
  text-decoration: none;
}
.c-footer .links-footer:hover {
  text-shadow: 0 0 1px #8E96A7;
}
.c-footer .links-footer:last-child {
  margin-bottom: 0px;
}
.c-footer .links-footer .icon-cel {
  height: 25px;
  margin-right: 4px;
}
.c-footer .box-images .img-certificado {
  height: 80px;
  object-fit: contain;
  margin-right: 32px;
}
.c-footer .box-images .img-certificado__last {
  width: 100%;
  margin-top: 8px;
}
.c-footer .method-pagament {
  border-top: #262626 1px solid;
  padding-top: 32px;
  padding-bottom: 32px;
}
.c-footer .method-pagament .pagament {
  margin-right: 12px;
}
.c-footer .method-pagament .pagament:last-child {
  margin-right: 0px;
}
.c-footer .app-img {
  margin-right: 8px;
  width: 40%;
}
.c-footer .app-img-mob {
  margin-right: 0px;
}
.c-footer .app-img img {
  width: 100%;
}
.c-footer .app-img:last-child {
  margin-right: 0px;
}
.c-footer .box-collapse {
  border-bottom: 1px solid #404040;
  padding-bottom: 16px;
  padding-top: 16px;
}
.c-footer .sign .sign-futfanatics {
  width: 125px;
}
@media (min-width: 1200px) {
  .c-footer .sign .sign-futfanatics {
    width: 40%;
  }
}
.c-footer .sign .content-sign {
  font-size: 14px;
  color: #8E96A7;
  line-height: 1.4rem;
  text-align: center;
  padding-top: 0px;
}
@media (min-width: 1200px) {
  .c-footer .sign .content-sign {
    padding: 16px;
    padding-top: 0px;
    text-align: start;
  }
}
.c-footer .sign .img-shared {
  width: 25px;
  height: 25px;
  margin: 0 8px;
}

/* HEader */
header {
  background: var(--color_black);
  position: sticky;
  top: 0;
  z-index: 10;
}
header .container-fluid {
  max-width: 1400px;
  padding-right: 30px;
  padding-left: 30px;
}
header .menu-mob-open {
  background: none;
  border: none;
  font-size: 25px;
  color: #8E96A7;
  padding: 0;
}
header .menu-mob-open:active, header .menu-mob-open:focus {
  outline: none;
}
header .logo {
  font-size: 32px;
  color: #8E96A7;
}
header .logo:hover {
  text-decoration: none;
}
header .busca form {
  background: #1B1C25;
  border: 1px solid var(--color_green);
  border-radius: 100px;
  padding: 10px 24px;
}
header .busca form input {
  background: #1B1C25;
  color: var(--color_green)!important;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #747474;
  margin-left: 15px;
  padding: 0;
}
header .busca form input{
  fill: var(--color_green);
}
header .busca form input::placeholder{
  color: var(--color_green)
}
header .busca form input:active, header .busca form input:focus {
  outline: none;
  box-shadow: none;
}
header .busca form button {
  border: 0;
  background: none;
  padding: 0;
  color: #8E96A7;
}
header .busca form button:focus {
  outline: none;
}
header .busca form button i {
  display: block;
}
header .links-usuario > a {
  color: #8E96A7;
  font-size: 24px;
}
header .links-usuario > a:hover {
  text-decoration: none;
}
header .links-usuario div > a {
  display: block;
  padding: 10px;
  border-bottom: 2px solid transparent;
  fill: var(--color_green);
}
header .links-usuario div > a.active {
  border-bottom-color: #8E96A7;
}
header .links-usuario .link-inter a {
  text-decoration: none;
  color: var(--color_green);
  fill: var(--color_green);
  gap: 8px;
}
header .busca form svg{
  fill: var(--color_green);
}
header .links-usuario .link-inter .icon-link-inter {
  margin-right: 8px;
}
header .links-usuario .central {
  position: relative;
}
header .links-usuario .central .menu-central {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background: white;
  border: 1px solid #DFE1E5;
  padding: 0 5px;
  text-align: center;
  z-index: 15;
  left: -47px;
  border-radius: 3px;
  margin-top: 6px;
  max-width: 140px;
}
header .links-usuario .central .menu-central:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid #DFE1E5;
  border-top: 1px solid #DFE1E5;
  background: white;
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
}
header .links-usuario .central .menu-central a {
  font-size: 14px;
  color: #8E96A7;
  border-bottom: 1px solid #DFE1E5;
  padding: 5px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
header .links-usuario .central .menu-central a:last-child {
  border-bottom: none;
}
header .links-usuario .cart-header {
  position: relative;
}
header .links-usuario .cart-header .cart-qty {
  background: #1E6200;
  font-size: 12px;
  font-weight: bold;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
}
header .links-usuario .cart-header .cart-qty.has-items {
  display: flex;
}
header .links-usuario .cart-header .menu-cart,
header .links-usuario .cart-header .cart-note {
  position: absolute;
  background: white;
  border: 1px solid #DFE1E5;
  padding: 0 10px;
  z-index: 15;
  right: 0;
  border-radius: 3px;
  margin-top: 6px;
}
header .links-usuario .cart-header .menu-cart:before,
header .links-usuario .cart-header .cart-note:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid #DFE1E5;
  border-top: 1px solid #DFE1E5;
  background: white;
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
  right: 15px;
}
header .links-usuario .cart-header .menu-cart > a,
header .links-usuario .cart-header .cart-note > a {
  display: block;
  white-space: nowrap;
  font-size: 14px;
  text-align: center;
  color: white;
  font-weight: bold;
  background: #8E96A7;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 10px 0;
}
header .links-usuario .cart-header .menu-cart .cart-products,
header .links-usuario .cart-header .menu-cart .cart-added,
header .links-usuario .cart-header .cart-note .cart-products,
header .links-usuario .cart-header .cart-note .cart-added {
  padding: 0;
  width: 400px;
  margin-top: 10px;
  margin-bottom: 0;
  max-height: 350px;
  overflow-y: scroll;
}
header .links-usuario .cart-header .menu-cart .cart-products li,
header .links-usuario .cart-header .menu-cart .cart-added li,
header .links-usuario .cart-header .cart-note .cart-products li,
header .links-usuario .cart-header .cart-note .cart-added li {
  list-style: none;
  border-bottom: 1px solid #DFE1E5;
  display: flex;
  padding: 10px 0;
  margin-right: 10px;
}
header .links-usuario .cart-header .menu-cart .cart-products li:first-child,
header .links-usuario .cart-header .menu-cart .cart-added li:first-child,
header .links-usuario .cart-header .cart-note .cart-products li:first-child,
header .links-usuario .cart-header .cart-note .cart-added li:first-child {
  padding-top: 0;
}
header .links-usuario .cart-header .menu-cart .cart-products li:last-child,
header .links-usuario .cart-header .menu-cart .cart-added li:last-child,
header .links-usuario .cart-header .cart-note .cart-products li:last-child,
header .links-usuario .cart-header .cart-note .cart-added li:last-child {
  border-bottom: none;
}
header .links-usuario .cart-header .menu-cart .cart-products li .product-photo,
header .links-usuario .cart-header .menu-cart .cart-added li .product-photo,
header .links-usuario .cart-header .cart-note .cart-products li .product-photo,
header .links-usuario .cart-header .cart-note .cart-added li .product-photo {
  width: 25%;
  margin-right: 2%;
}
header .links-usuario .cart-header .menu-cart .cart-products li .product-photo a,
header .links-usuario .cart-header .menu-cart .cart-added li .product-photo a,
header .links-usuario .cart-header .cart-note .cart-products li .product-photo a,
header .links-usuario .cart-header .cart-note .cart-added li .product-photo a {
  padding: 0;
}
header .links-usuario .cart-header .menu-cart .cart-products li .product-data,
header .links-usuario .cart-header .menu-cart .cart-added li .product-data,
header .links-usuario .cart-header .cart-note .cart-products li .product-data,
header .links-usuario .cart-header .cart-note .cart-added li .product-data {
  font-size: 14px;
  font-weight: 500;
  color: #747474;
  width: 73%;
}
header .links-usuario .cart-header .menu-cart .cart-products li .product-data h3,
header .links-usuario .cart-header .menu-cart .cart-added li .product-data h3,
header .links-usuario .cart-header .cart-note .cart-products li .product-data h3,
header .links-usuario .cart-header .cart-note .cart-added li .product-data h3 {
  font-size: 14px;
  color: #8E96A7;
  margin-bottom: 5px;
}
header .links-usuario .cart-header .menu-cart .cart-products li .product-data .product-remove,
header .links-usuario .cart-header .menu-cart .cart-added li .product-data .product-remove,
header .links-usuario .cart-header .cart-note .cart-products li .product-data .product-remove,
header .links-usuario .cart-header .cart-note .cart-added li .product-data .product-remove {
  font-size: 26px;
  color: #747474;
  background: none;
  padding: 0;
  border: 0;
}
header .links-usuario .cart-header .menu-cart .cart-products li .product-data .product-price,
header .links-usuario .cart-header .menu-cart .cart-added li .product-data .product-price,
header .links-usuario .cart-header .cart-note .cart-products li .product-data .product-price,
header .links-usuario .cart-header .cart-note .cart-added li .product-data .product-price {
  font-size: 16px;
  font-weight: 900;
  color: #8E96A7;
  text-align: right;
}
header .links-usuario .cart-header .menu-cart .cart-subtotal,
header .links-usuario .cart-header .cart-note .cart-subtotal {
  font-size: 16px;
  text-align: center;
  color: #8E96A7;
}
header .links-usuario .cart-header .menu-cart .cart-subtotal strong,
header .links-usuario .cart-header .cart-note .cart-subtotal strong {
  font-weight: 900;
}
header .links-usuario .cart-header .menu-cart .cart-added,
header .links-usuario .cart-header .cart-note .cart-added {
  max-height: unset;
  overflow-y: auto;
}
header .links-usuario .cart-header .menu-cart .cart-added h3,
header .links-usuario .cart-header .cart-note .cart-added h3 {
  font-size: 16px;
  font-weight: 900;
}
header .links-usuario .cart-header .menu-cart .cart-added .product-msg,
header .links-usuario .cart-header .cart-note .cart-added .product-msg {
  color: #8E96A7;
}
header .header-top {
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.1);
}
header .header-infos {
  background: var(--color_black);
  z-index: 2;
}
header .header-nav {
  background: #1B1C25;
  transition: top 0.3s ease;
}
header .header-nav nav > ul > li.parent > a {
  line-height: 1;
  color: #fff;
  font-size: 14px;
  margin-left: 6px;
  text-decoration: none;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
header .header-nav nav > ul > li.parent > a .icon-down-menu {
  height: 7px;
  margin: 0 10px;
}
header .header-nav nav > ul > li .title-hexa {
  font-weight: 700;
  text-decoration: none;
}
header .header-nav .tag-new {
  font-size: 10px;
  font-weight: bold;
  color: #8E96A7;
  background: #1E6200;
  padding: 1px 8px;
  margin-left: 5px;
  border-radius: 20px;
}

.banner-app {
  background: #DFE1E5;
  padding-top: 10px;
  padding-bottom: 10px;
}
.banner-app .btn-fechar {
  background: none;
  border: 0;
  margin-left: 10px;
  padding: 0;
}
.banner-app i {
  font-size: 26px;
  background: white;
  border-radius: 10px;
  margin-right: 10px;
  padding: 9px 5px;
  color: #8E96A7;
}
.banner-app p {
  font-size: 12px;
  color: #747474;
  margin-bottom: 0;
}
.banner-app p.title {
  font-size: 16px;
  color: #8E96A7;
  font-weight: 900;
}
.banner-app .link-app {
  padding: 10px 30px;
  margin-left: 10px;
}

.banner-top-site {
  position: relative;
  z-index: 1;
}
.banner-top-site:not(.slick-initialized) > div:not(:first-child) {
  display: none;
}
.banner-top-site .slick-slide > div {
  line-height: 0;
}

.bg-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #808094;
  opacity: 0.5;
  top: 0;
  left: 0;
  display: none;
}

@media (min-width: 992px) {
  .menu-mob {
    display: none;
  }
  .links-usuario .link-inter a {
    font-size: 12px;
    border-radius: 100px;
    padding: 8px 13px 8px 10px !important;
    border: 1px solid !important;
    transition: all 0.2s ease;
  }
  .links-usuario .link-inter a i {
    font-size: 24px;
    margin-right: 5px;
  }
  .links-usuario .link-inter a:focus {
    outline: none;
  }
  .links-usuario .link-inter a:hover {
    color: var(--color_green);
    border-color: var(--color_green) !important;
  }
  .header-nav .col-12 {
    position: unset;
  }
  .header-nav nav > ul {
    padding: 0;
    margin: 0;
  }
  .header-nav nav > ul > li {
    list-style: none;
  }
  .header-nav nav > ul > li > a {
    font-size: 14px;
    text-transform: uppercase;
    color: #8E96A7;
    padding: 15px 0;
    display: block;
    position: relative;
  }
  .header-nav nav > ul > li > a:hover {
    text-decoration: none;
    color: var(--color_green);
  }
  .header-nav nav > ul > li > a:before {
    content: "";
    width: 0;
    height: 2px;
    background: var(--color_green);
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all 0.2s ease;
  }
  .header-nav nav > ul > li.parent > a:after {
    font-family: "icons-futfanatics" !important;
    line-height: 1;
    color: #747474;
    font-size: 8px;
    margin-left: 6px;
    vertical-align: middle;
  }
  .header-nav nav > ul > li .child-container {
    position: absolute;
    z-index: 10;
    background: var(--color_black);
    width: 100%;
    left: 0;
    font-size: 12px;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
  .header-nav nav > ul > li .child-container .column {
    flex-grow: 1;
    flex-basis: 0;
  }
  .header-nav nav > ul > li .child-container .column > li {
    margin-bottom: 9px;
  }
  .header-nav nav > ul > li .child-container .column > li:last-child {
    margin-bottom: 0;
  }
  .header-nav nav > ul > li .child-container ul {
    padding: 0;
    margin: 0;
  }
  .header-nav nav > ul > li .child-container ul li {
    list-style: none;
    margin-right: 10px;
  }
  .header-nav nav > ul > li .child-container ul li:last-child {
    margin-right: 0;
  }
  .header-nav nav > ul > li .child-container ul li a {
    font-weight: 500;
    color: #8E96A7;
    display: inline-block;
    transition: all 0.2s ease;
    text-decoration: none;
  }
  .header-nav nav > ul > li .child-container ul li a.menu-title {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #fff;
    display: block;
    text-decoration: none;
  }
  .header-nav nav > ul > li .child-container ul li a:hover {
    text-shadow: 0 0 1px #8E96A7;
  }
  .header-nav nav > ul > li .child-container ul li.menu-links {
    margin-top: 25px;
  }
  .header-nav nav > ul > li .child-container ul li.menu-links a {
    min-width: 160px;
    margin-top: 10px;
    padding: 5px 10px;
    color: var(--color_green);
    background: none;
    border: 1px solid var(--color_green);
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    transition: all 0.2s ease;
  }
  .header-nav nav > ul > li .child-container ul li.menu-links a:hover {
    text-decoration: none;
    background: var(--color_green);
    color: var(--color_black);
  }
  .header-nav nav > ul > li .child-container ul li .see-all {
    color: #8E96A7;
  }
  .header-nav nav > ul > li:hover > a {
    color: var(--color_green) !important;
  }
  .header-nav nav > ul > li:hover > a:before {
    width: 100%;
  }
  .header-nav nav > ul > li:hover .child-container {
    height: auto;
    opacity: 1;
    padding-bottom: 20px;
  }
  .header-nav nav .menu-img a {
    margin-left: 20px;
  }
  .header-nav nav .menu-img a img {
    border-radius: 10px;
  }
}
@media (max-width: 991px) {
  .menu-desk {
    display: none;
  }
  header .logo {
    font-size: 30px;
  }
  /* header .busca {
    margin-top: 15px;
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100vw - 5px) !important;
  } */
  header .busca form {
    border-radius: 0;
  }
  header .busca form input {
    margin-left: 0;
    margin-right: 15px;
  }
  .menu-open {
    overflow: hidden;
  }
  .menu-open header {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
  }
  .header-top {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .header-nav {
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    background: #1f1f1f;
    position: absolute;
    z-index: 9999;
  }
  .header-nav nav .nav-top {
    padding: 20px 15px;
    background-color: var(--color_black);
  }
  .header-nav nav .nav-top button,
  .header-nav nav .nav-top a {
    padding: 0;
    border: 0;
    background: none;
    font-size: 25px;
    color: #d7d7d7;
  }
  .header-nav nav .nav-top span {
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    color: #d7d7d7;
  }
  .header-nav nav ul {
    padding: 0;
    position: absolute;
    width: 100%;
    height: calc(100% - 65px);
    left: 0;
    background: #1f1f1f;
    z-index: 1;
    overflow-y: auto;
  }
  .header-nav nav ul li {
    list-style: none;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #8E96A7;
    display: block;
    border-top: 1px solid #404040;
    margin: 0 15px;
  }
  .header-nav nav ul li:first-child {
    border-top: 0;
  }
  .header-nav nav ul li.link-user {
    margin-left: 0;
    margin-right: 0;
    background: var(--color_green);
    color: var(--color_black);
    text-decoration: none;
  }
  .header-nav nav ul li.link-user i {
    position: relative;
    margin-right: 5px;
  }
  .header-nav nav ul li.link-user + li {
    border-top: 0;
  }
  .header-nav nav ul li.link-user a {
    text-decoration: none;
  }
  .header-nav nav ul li.back {
    color: var(--color_black);
    background: var(--color_green);
    position: relative;
    text-align: center;
    border-bottom: none;
    margin-left: 0;
    margin-right: 0;
    padding: 15px;
  }
  .header-nav nav ul li.back i {
    font-size: 26px;
    position: absolute;
    top: calc(50% - 12px);
    left: 20px;
  }
  .header-nav nav ul li.menu-feature {
    background: #333333;
    margin-left: 0;
    margin-right: 0;
    padding-left: 15px;
    border-top: 1px solid #404040;
    padding-right: 15px;
  }
  .header-nav nav ul li > a {
    color: #d7d7d7;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    text-decoration: none;
    align-items: center;
  }
  .header-nav nav ul li > a .icon-right,
  .header-nav nav ul li > a .icon-left {
    height: 10px;
  }
  .header-nav nav ul li > a i {
    font-size: 26px;
    position: absolute;
    top: calc(50% - 12px);
    right: 0;
  }
  .header-nav nav ul li > a.link-inter {
    font-size: 12px;
    border-radius: 100px;
    padding: 8px 13px 8px 10px !important;
    border: 1px solid #8E96A7 !important;
    margin: 15px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .header-nav nav ul li > a.link-inter i {
    font-size: 24px;
    margin-right: 5px;
    position: unset;
  }
  .header-nav nav ul li ul {
    display: none;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
.octa-widget-v2 {
  display: none;
}

@media (max-width: 1400px) {
  .c-banners_shirt .banner {
    max-height: 360px;
  }
}
@media (min-width: 1400px) {
  .c-banners_shirt .banner {
    max-height: 420px;
  }
}
.c-cart-header {
  position: relative;
}
.c-cart-header .qnty {
  position: absolute;
  right: -5px;
  top: 0px;
  font-size: 12px;
  font-weight: bold;
  background: var(--color_green);
  color: var(--color_black);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-note {
  position: absolute;
  background: #262626;
  border: 1px solid #404040;
  padding: 0 10px;
  z-index: 15;
  border-radius: 3px;
  margin-top: 6px;
  right: 5px;
  top: 50px;
  padding-top: 10px;
  width: 200px;
}
.cart-note:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid #404040;
  border-top: 1px solid #404040;
  background: #262626;
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
  right: 15px;
}
.cart-note h3 {
  font-size: 14px;
  color: #8E96A7;
  margin-bottom: 5px;
  text-align: center;
}
.cart-note > a {
  display: block;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  color: white;
  font-weight: bold;
  background: #fdc800;
  color: var(--color_black);
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 10px 0;
}
.cart-note > a:hover {
  color: var(--color_black);
}

.c-forms input,
.c-forms select {
  width: 100%;
  background: #232323;
  border: none;
  border-bottom: 1px solid #8E96A7;
  color: #fff;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0;
  outline: none;
}
.c-forms input:focus,
.c-forms select:focus{
  border-bottom: 1px solid var(--color_green);
}
.c-forms input::placeholder{
  color: #fff;
}
.c-forms .forms-input__text {
  margin-bottom: 16px;
}
@media (min-width: 576px) {
  .c-forms .forms-input__text {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.c-forms .forms-input__send {
  width: 100%;
}
.c-forms .forms-input__send .btn-envio {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: none;
  width: 100%;
  min-width: 130px;
  font-size: 14px;
  background-color: #00db5c;
  color: var(--color_black);
  font-weight: 700;
  border-radius: 5px;
}
.install-texto-destaque{
  color: var(--color_green);
}
/*cyber*/
.c-forms .forms-input__send .btn-envio.cyber {
  background-color: var(--color_blue);
}
.header-top.cyber .header-nav .tag-new {
  color: #8E96A7;
  background: var(--color_blue);
}
.header-top.cyber .link-inter a {
  text-decoration: none;
  color: var(--color_blue);
}
.header-top.cyber .link-inter .icon-link-inter {
  margin-right: 8px;
}

@media (min-width: 992px) {
  .header-top.cyber .links-usuario .link-inter a {
    color: var(--color_blue);
    fill: var(--color_blue);
  }
  .header-top.cyber .links-usuario .link-inter a:hover {
    color: var(--color_blue);
    border-color: var(--color_blue) !important;
  }
  .header-top.cyber .header-nav nav > ul > li > a:hover {
    color: var(--color_blue);
  }
  .header-top.cyber .header-nav nav > ul > li > a:before {
    background: var(--color_blue);
  }
  .header-top.cyber .header-nav nav > ul > li .child-container ul li.menu-links a {
    border: 1px solid var(--color_blue);
    color: var(--color_blue);
  }
  .header-top.cyber .header-nav nav > ul > li .child-container ul li.menu-links a:hover {
    background: var(--color_blue);
    color: var(--color_black);
  }
  .header-top.cyber .header-nav nav > ul > li .child-container ul li .see-all {
    color: #8E96A7;
  }
  .header-top.cyber .header-nav nav > ul > li:hover > a {
    color: var(--color_blue) !important;
  }
}
@media (max-width: 991px) {
  .header-top.cyber .header-nav nav ul li.link-user {
    background: var(--color_blue);
  }
  .header-top.cyber .header-nav nav ul li.back {
    background: var(--color_blue);
  }
}
.header-top.cyber .c-cart-header .qnty {
  background: var(--color_blue);
}
.header-top.cyber .cart-note h3 {
  color: #8E96A7;
}
.header-top.cyber .cart-note > a {
  background: var(--color_blue);
}
header .cyber .busca form{
  border: 1px solid var(--color_blue);
}
header .cyber .busca form input,
header .cyber .busca form input::placeholder{
  color: var(--color_blue);
}
header .cyber .busca form svg,
header .cyber .links-usuario div > a{
  fill: var(--color_blue);
}
.install-texto-destaque.cyber{
  color: var(--color_blue);
}


.c-marcas .img-marca{
  padding: 12px;
  color: #fff;
  background: #232323;
  border-radius: 16px;
  font-size: 50px;
  text-decoration: none;
}

@media(max-width: 767px){
  .slick-prev, .slick-next{
    display: none!important;
  }
}
.app-install{
  max-width: 100%;
  padding: 1rem;
}
.slick-dots li{
  width: auto!important;
  height: auto!important;
}
.slick-dots li button{
  width: 6px!important;
  height: 6px!important;
  padding: 0!important;
  background: #fff!important;
  border-radius: 4px!important;
  opacity: .75;
  transition: .2s ease;
}
.slick-dots li.slick-active button{
  width: 25px!important;
  opacity: 1;
}
.slick-dots li button:before,
  .slick-dots li.slick-active button:before{
  display: none!important;
}