@font-face {
  font-family: 'icomoon';
  src:
    url('icomoon.ttf') format('truetype'),
    url('icomoon.woff') format('woff'),
    url('icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-braziline:before {
  content: "\e900";
  color: #fff;
}
.icon-coca-cola:before {
  content: "\e901";
  color: #fff;
}
.icon-fila:before {
  content: "\e902";
  color: #fff;
}
.icon-hang-loose:before {
  content: "\e903";
  color: #fff;
}
.icon-hurley:before {
  content: "\e904";
  color: #fff;
}
.icon-kenner:before {
  content: "\e905";
  color: #fff;
}
.icon-kappa:before {
  content: "\e906";
  color: #fff;
}
.icon-nb:before {
  content: "\e907";
  color: #fff;
}
.icon-nicoboco:before {
  content: "\e908";
  color: #fff;
}
.icon-oakley:before {
  content: "\e909";
  color: #fff;
}
.icon-olympikus:before {
  content: "\e90a";
  color: #fff;
}
.icon-puma:before {
  content: "\e90b";
  color: #fff;
}
.icon-redley:before {
  content: "\e90c";
  color: #fff;
}
.icon-umbro:before {
  content: "\e90d";
  color: #fff;
}